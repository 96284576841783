import { get, post } from "./base";

/**
 * @param {any} data
 */
export function login(data) {
    return post("/print/login", data)
}

export function getMatch(params) {
    return get("/match/list", params)
}

// export function 

/**
 * @param {any} params
 */
export function getTicket(params) {
    return get("/print/ticket", params)
}
