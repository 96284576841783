import React, { useEffect, useMemo, useState } from 'react';
import { getTicket } from '../axios';
import { useLocation, useParams } from 'react-router-dom';

export default function PrintTicket() {
    const params = useParams();
    const betId = useMemo(() => params.id, [params]);
    const location = useLocation();
    const ocid = useMemo(() => new URLSearchParams(location.search).get("ocid"), [location.search]);
    const [tickets, setTickets] = useState([]);
    useEffect(() => {
        getTicket(({betId, ocid})).then(({data}) => {
            if(data.status === "error") {
              alert(data.errorMessage)
            } else {
                setTickets(data.tickets);
                setTimeout(() => {
                    window.print();
                }, 0.5 * 1000)
            }
        });
    }, [betId, ocid])
    return (
        <div>
            {tickets.map((ticket) => (
                <div style={{ height: "780px" }}>
                    <img src={ticket} alt="" style={{ width: "313px" }}/>
                    {/* <img src={ticket} alt="" style={{ 
                        width: "274px",
                        marginLeft: "-12.4px",
                        height: "745px"
                     }}/> */}
                </div>
            ))}

            <div className="print-hide" onClick={() => window.print()} style={{
                position: "absolute",
                top: "100px",
                right: "20px",
                cursor: "pointer",
                border: "solid 1px",
                padding: "10px",
                fontSize: "15px",

            }}>立即打印</div>
        </div>
    )
}