import React, { useEffect, useState, useRef }  from 'react';
import { Button, ErrorBlock, SafeArea } from 'antd-mobile'
import { ErrorBoundary, useErrorBoundary } from "react-error-boundary";

import './App.css';

import { HashRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import Login from "./modules/login.tsx"
import PrintTicket from './modules/ticket.tsx';

function AppContainer() {
  return (
    <div className="App">
        <SafeArea position='top' />
        <div className='body'>
          <Routes>
            <Route path="/" >
              <Route path="login" Component={Login}></Route>
              <Route path='ticket/:id' Component={PrintTicket}></Route>
            </Route>
          </Routes>
        </div>
      </div>
  )
}

function ErrorFallback() {
  const navigate = useNavigate();
  const { resetBoundary } = useErrorBoundary();

  return (
    <ErrorBlock
        image='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
        style={{
          '--image-height': '150px',
        }}
      >
        <Button color='primary' onClick={() => {navigate(-1); resetBoundary()}}>返回上一页</Button>
      </ErrorBlock>
  )
}

function App() {
  return (
    <Router>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <AppContainer />
        </ErrorBoundary>
      </Router>
  );
}

export default App;
